import { Grid } from "@material-ui/core";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import {
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  TabbedForm,
  TextField,
  TextInput,
  ImageField,
  ImageInput,
  Create,
  required,
  DateField,
} from "react-admin";
import ImageDeleteField from "../../utils/getImages";

export const LittersList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Nazwa miotu" />
      <DateField source="birthDate" label="Data urodzenia" />
      <EditButton basePath="/litters" label="Edytuj" />
      <DeleteButton basePath="/litters" label="Usuń" />
    </Datagrid>
  </List>
);

export const LittersEdit = (props) => (
  <Edit {...props} title="Edytuj miot">
    <TabbedForm>
      <FormTab label="Informacje o miocie">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput
              source="name"
              label="Nazwa miotu:"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs="2">
            <DateInput
              source="birthDate"
              label="Data urodzenia:"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          fullWidth
          source="description_pl"
          label="Opis miotu po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          fullWidth
          source="description_en"
          label="Opis miotu po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2} fullWidth>
          <Grid item xs="12">
            <ImageInput
              source="mainImg"
              label="Zdjęcie Główne"
              accept="image/*"
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="6">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/*"
              multiple
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs="6">
            <ImageInput
              source="panorama"
              label="Panorama"
              accept="image/*"
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageDeleteField source="images" />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const LittersCreate = (props) => (
  <Create {...props} title="Stwórz nowy miot">
    <TabbedForm>
      <FormTab label="Informacje o miocie">
        <Grid fullWidth>
          <TextInput source="name" label="Nazwa miotu:" validate={required()} />
          <DateInput
            source="birthDate"
            label="Data urodzenia:"
            validate={required()}
          />
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          source="description_pl"
          label="Opis miotu po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          source="description_en"
          label="Opis miotu po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container fullWidth>
          <Grid item mr="10px">
            <ImageInput
              source="mainImg"
              label="Zdjęcie Główne"
              accept="image/*"
              fullWidth
              validate={required()}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container fullWidth>
          <Grid item m="10px">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/*"
              fullWidth
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs="6">
            <ImageInput
              source="panorama"
              label="Panorama"
              accept="image/*"
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
