import { Admin, Resource } from "react-admin";
import authProvider from "./config/authProvider";
import myDataProvider from "./config/dataProvider";
import { DogsCreate, DogsEdit, DogsList } from "./Components/Dogs/Dogs";
import {
  LittersEdit,
  LittersList,
  LittersCreate,
} from "./Components/Litters/Litters";
import {
  AboutusList,
  AboutusCreate,
  AboutusEdit,
} from "./Components/Aboutus/Aboutus";

import {
  AboutBreedList,
  AboutBreedCreate,
  AboutBreedEdit,
} from "./Components/AboutBreed/AboutBreed";

import {
  PuppiesList,
  PuppyEdit,
  PuppyCreate,
} from "./Components/Puppies/puppies";

import {
  MainPageCreate,
  MainPageEdit,
  MainPageList,
} from "./Components/MainPage/MainPage";
import { NewsList, NewsEdit, NewsCreate } from "./Components/News/News";
import { ImageCreate, ImageList } from "./Components/Images/Images";

export const App = () => (
  <Admin authProvider={authProvider} dataProvider={myDataProvider}>
    <Resource name="dogs" list={DogsList} edit={DogsEdit} create={DogsCreate} />
    <Resource name="news" list={NewsList} edit={NewsEdit} create={NewsCreate} />
    <Resource name="dogs" list={DogsList} edit={DogsEdit} create={DogsCreate} />
    <Resource
      name="litters"
      list={LittersList}
      edit={LittersEdit}
      create={LittersCreate}
    />
    <Resource
      name="puppies"
      list={PuppiesList}
      edit={PuppyEdit}
      create={PuppyCreate}
    />
    <Resource
      name="aboutus"
      list={AboutusList}
      edit={AboutusEdit}
      create={AboutusCreate}
    />
    <Resource
      name="aboutbreed"
      list={AboutBreedList}
      edit={AboutBreedEdit}
      create={AboutBreedCreate}
    />
    <Resource
      name="mainpage"
      list={MainPageList}
      edit={MainPageEdit}
      create={MainPageCreate}
    />
    <Resource name="files" list={ImageList} create={ImageCreate} />
  </Admin>
);
