import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  ImageField,
  required,
  UrlField,
} from "react-admin";

export const ImageList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <ImageField source="path" label="Zdjęcię" />
      <UrlField source="path" label="URL" />
      <EditButton basePath="/files" label="Edytuj" />
      <DeleteButton basePath="/files" label="Usuń" />
    </Datagrid>
  </List>
);

export const ImageCreate = (props) => (
  <Create title="Dodaj nowe zdjęcię" {...props}>
    <TabbedForm>
      <FormTab label="Zdjęcie">
        <ImageInput
          source="image"
          label="Gallery"
          accept="image/*"
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
