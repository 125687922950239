import {
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleShowLayout,
} from "react-admin";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import SimpleArray from "../../utils/getImages";
import { Grid } from "@material-ui/core";

export const DogsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Imię" />
      <TextField source="pedigreename" label="Imię rodowodowe" />
      <TextField source="gender" label="Płeć" />
      <TextField source="imported" label="Importowany" />
      <EditButton basePath="/dogs" label="Edytuj" />
      <DeleteButton basePath="/dogs" label="Usuń" />
    </Datagrid>
  </List>
);

export const DogsEdit = (props) => (
  <Edit {...props} title="Edytuj psa">
    <TabbedForm>
      <FormTab label="Dane psa">
        <Grid container spacing="2">
          <Grid item xs="3" fullWidth>
            <TextInput
              source="pedigreename"
              label="Imię Rodowodowe"
              fullWidth
            />
          </Grid>
          <Grid item xs="3" fullWidth>
            <TextInput source="name" label="Imię Domowe" fullWidth />
          </Grid>
          <Grid item xs="3">
            <DateInput source="birthDate" label="Data urodzenia" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput
              source="imported"
              label="Importowany z kraju:"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} fullWidth>
          <Grid item xs="4">
            <NumberInput source="growth" label="Wzrost" fullWidth />
          </Grid>
          <Grid item xs="4">
            <NumberInput source="weight" label="Waga" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2} fullWidth>
          <Grid item xs="3">
            <TextInput source="owner" label="Właściciel" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="breeder" label="Hodowca" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="mothername" label="Nazwa Matki" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="fathername" label="Nazwa Ojca" fullWidth />
          </Grid>
        </Grid>

        <Grid container fullWidth spacing={2}>
          <Grid item xs="6">
            <SelectInput
              source="gender"
              label="Płeć"
              choices={[
                { id: "male", name: "Pies" },
                { id: "female", name: "Suka" },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs="6">
            <TextInput
              source="pedigreeLink"
              label="Link do Rodowodu"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container fullWidth>
          <Grid item xs="4" fullWidth>
            <ReferenceArrayInput source="litters" reference="litters">
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput source="tail_pl" label="Ogon po polsku" fullWidth />
          </Grid>
        </Grid>
        <RichTextInput
          source="tests_pl"
          label="Badania psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
        <RichTextInput
          source="description_pl"
          label="Opis psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput source="tail_en" label="Ogon po angielsku" fullWidth />
          </Grid>
        </Grid>
        <RichTextInput
          source="tests_en"
          fullWidth
          label="Badania psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
        <RichTextInput
          source="description_en"
          fullWidth
          label="Opis psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <ImageInput
              source="mainImg"
              label="Zdjęcie główne Lewe"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs="6">
            <ImageInput
              source="mainImgRight"
              label="Zdjęcie główne Prawe"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/*"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const DogsCreate = (props) => (
  <Create {...props} title="Dodaj nowego psa">
    <TabbedForm>
      <FormTab label="Dane psa">
        <Grid container spacing="2">
          <Grid item xs="3" fullWidth>
            <TextInput
              source="pedigreename"
              label="Imię Rodowodowe"
              fullWidth
            />
          </Grid>
          <Grid item xs="3" fullWidth>
            <TextInput source="name" label="Imię Domowe" fullWidth />
          </Grid>
          <Grid item xs="3">
            <DateInput source="birthDate" label="Data urodzenia" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput
              source="imported"
              label="Importowany z kraju:"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} fullWidth>
          <Grid item xs="4">
            <NumberInput source="growth" label="Wzrost" fullWidth />
          </Grid>
          <Grid item xs="4">
            <NumberInput source="weight" label="Waga" fullWidth />
          </Grid>
          <Grid item xs="4">
            <TextInput source="tail_pl" label="Ogon" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2} fullWidth>
          <Grid item xs="3">
            <TextInput source="owner" label="Właściciel" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="breeder" label="Hodowca" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="mothername" label="Nazwa Matki" fullWidth />
          </Grid>
          <Grid item xs="3">
            <TextInput source="fathername" label="Nazwa Ojca" fullWidth />
          </Grid>
        </Grid>

        <Grid container fullWidth spacing={2}>
          <Grid item xs="6">
            <SelectInput
              source="gender"
              label="Płeć"
              choices={[
                { id: "male", name: "Pies" },
                { id: "female", name: "Suka" },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs="6">
            <TextInput
              source="pedigreeLink"
              label="Link do Rodowodu"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container fullWidth>
          <Grid item xs="4" fullWidth>
            <ReferenceArrayInput source="litters" reference="litters">
              <SelectArrayInput optionValue="id" label="Mioty" fullWidth />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput source="tail_pl" label="Ogon po polsku" fullWidth />
          </Grid>
        </Grid>
        <RichTextInput
          source="tests_pl"
          label="Badania psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
        <RichTextInput
          source="description_pl"
          label="Opis psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput source="tail_en" label="Ogon po angielsku" fullWidth />
          </Grid>
        </Grid>
        <RichTextInput
          source="tests_en"
          fullWidth
          label="Badania psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
        <RichTextInput
          source="description_en"
          fullWidth
          label="Opis psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <ImageInput
              source="mainImg"
              label="Zdjęcie główne Lewe"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs="6">
            <ImageInput
              source="mainImgRight"
              label="Zdjęcie główne Prawe"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/*"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
